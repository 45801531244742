import { NatureOfPerson } from '@paid-ui/enums/party';

import { defaultAddress } from './address';

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  LIVE = 'LIVE',
}

export enum PartyType {
  PAYER = 'PAYER',
  PAYEE = 'PAYEE',
  ADDITIONAL_PAYER = 'ADDITIONAL_PAYER',
  ADDITIONAL_PAYEE = 'ADDITIONAL_PAYEE',
  SUPERINTENDENT = 'SUPERINTENDENT',
}

export enum PartyRole {
  INVITER = 'INVITER',
  INVITEE = 'INVITEE',
}

export enum PartyLinkingStatus {
  'UNLINKED' = 'UNLINKED',
  'LINKED' = 'LINKED',
  'FILLED_INFO' = 'FILLED_INFO',
  'READY_TO_SIGN' = 'READY_TO_SIGN',
  'SIGNED' = 'SIGNED',
  'SIGNING' = 'SIGNING',
  'DECLINED_ON_PROVIDER' = 'DECLINED_ON_PROVIDER',
  'JOINED' = 'JOINED',
}

export const emptyHomeOwner = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  disabled: false,
};

export const emptyDirector = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
};

export const emptyTrustee = {
  isNewAdded: true,
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
};

export const emptyIndividual = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  homeAddress: defaultAddress,
  disabled: false,
};

export const natureOfPersonMap = {
  [NatureOfPerson.SELF]: 'Self',
  [NatureOfPerson.POWER_OF_ATTORNEY]: 'Power of attorney',
  [NatureOfPerson.GUARDIAN]: 'Guardian',
  [NatureOfPerson.EXECUTOR]: 'Executor',
  [NatureOfPerson.LEGAL_REPRESENTATIVE]: 'Legal representative',
  [NatureOfPerson.DIRECTOR_OF_CORPORATION]: 'Director of corporation',
  [NatureOfPerson.OTHER]: 'Other',
};

export enum PayType {
  PAYER = 'PAYER',
  PAYEE = 'PAYEE',
  INTERMEDIARY = 'INTERMEDIARY',
}
