import { ProjectRole, ProjectType } from '@paid-ui/enums/project';

export enum ActionType {
  'CREATE' = 'CREATE',
  'UPDATE' = 'UPDATE',
}

export enum OwnerExistingStatus {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
  INACTIVE = 'INACTIVE',
}

export const projectTypeMap = {
  [ProjectType.DOMESTIC_RESIDENTIAL]: 'Domestic residential',
  [ProjectType.MULTI_RESIDENTIAL]: 'Multi residential',
  [ProjectType.COMMERCIAL]: 'Commercial',
  [ProjectType.INDUSTRIAL]: 'Industrial',
  [ProjectType.CIVIL]: 'Civil',
  [ProjectType.REAL_ESTATE]: 'Real estate',
};

export const projectRoleMap = {
  [ProjectRole.BUILDER]: 'Builder / Head contractor',
  [ProjectRole.SUBCONTRACTOR]: 'Subcontractor',
  [ProjectRole.SUPPLIER]: 'Supplier',
  [ProjectRole.PRINCIPAL]: 'Property owner / Principal',
  [ProjectRole.SUPERINTENDENT]: 'Agent / Superintendent',
  [ProjectRole.ARCHITECT]: 'Architect',
} as Record<ProjectRole, string>;
