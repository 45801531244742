export const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.5C9 5.84315 10.3431 4.5 12 4.5C13.6569 4.5 15 5.84315 15 7.5C15 9.15685 13.6569 10.5 12 10.5C10.3431 10.5 9 9.15685 9 7.5ZM12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5ZM8 14.5C6.67392 14.5 5.40215 15.0268 4.46447 15.9645C3.52678 16.9021 3 18.1739 3 19.5V21.5C3 22.0523 3.44772 22.5 4 22.5C4.55228 22.5 5 22.0523 5 21.5V19.5C5 18.7043 5.31607 17.9413 5.87868 17.3787C6.44129 16.8161 7.20435 16.5 8 16.5H16C16.7956 16.5 17.5587 16.8161 18.1213 17.3787C18.6839 17.9413 19 18.7044 19 19.5V21.5C19 22.0523 19.4477 22.5 20 22.5C20.5523 22.5 21 22.0523 21 21.5V19.5C21 18.1739 20.4732 16.9021 19.5355 15.9645C18.5979 15.0268 17.3261 14.5 16 14.5H8Z"
      />
    </svg>
  );
};
