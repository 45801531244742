import { BackgroundCheckStatus } from '@paid-ui/enums/account';
import { useIsomorphicEffect } from '@paid-ui/hooks/use-isomorphic-effect';
import { type RemoteConfig, saveRemoteConfig } from '@paid-ui/models/app-config';
import { saveDefaultFeatures } from '@paid-ui/models/feature';
import { type Product, saveProducts } from '@paid-ui/models/product';
import { saveProfile, saveTransactionAccounts, signIn, userManager } from '@paid-ui/models/user';
import { saveUserGroup } from '@paid-ui/models/user-group';
import { type UserAccount, type UserDecoded, type UserDetail } from '@paid-ui/types/parties';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { nowMel } from '@paid-ui/utils/datetime';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio/react';

const handleTokenEvent = (event: StorageEvent) => {
  if (!event.newValue) {
    return;
  }
  if (event.key === 'get_session') {
    window.localStorage.setItem(
      'access_token',
      window.sessionStorage?.getItem('access_token') || '',
    );
    window.localStorage.removeItem('access_token');
  }
  if (event.key === 'access_token') {
    window.sessionStorage.setItem('access_token', event?.newValue || '');
    window.localStorage.removeItem('get_session');
  }
};

type GetAppConfigResponse = {
  data: RemoteConfig;
} | null;

type GetProductListResponse = {
  data: Product[];
} | null;

type GetUserProfileResponse = {
  data: UserDetail;
} | null;

type GetTransactionAccountsResponse = {
  data: UserAccount[];
} | null;

export const useGetUserProfile = () => {
  const toast = useToast();
  const router = useRouter();
  const mountedRef = useRef(false);
  const [userId, setUserId] = useState('');
  const { forceUpdate } = useSnapshot(userManager);
  const isApplyAccountFlyoutOpen = router.query.dialog === 'apply-account';

  const redirectToSignIn = (message?: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('next', window.location.pathname);
    urlSearchParams.sort();
    router.replace(`/signin?${urlSearchParams.toString()}`);
    sessionStorage.removeItem('access_token');
    if (message) {
      toast.error(message);
    }
  };

  const configQuery = useQuery({
    enabled: Boolean(userId),
    queryKey: ['GetAppConfig'],
    queryFn: async () => {
      const { data } = await request.get<GetAppConfigResponse>('/app/config');
      return data?.data;
    },
    onSuccess(data) {
      if (!data) {
        redirectToSignIn('Failed to get app config.');
        return;
      }
      saveRemoteConfig(data);
      saveDefaultFeatures(data.features);
    },
    onError(error) {
      redirectToSignIn(error instanceof Error ? error.message : 'Failed to get app config.');
    },
  });

  const productsQuery = useQuery({
    enabled: Boolean(userId),
    queryKey: ['GetProductList'],
    queryFn: async () => {
      const { data } = await request.get<GetProductListResponse>('/products');
      return data?.data ?? [];
    },
    onSuccess(data) {
      saveProducts(data);
    },
  });

  const transactionAccountsQuery = useQuery({
    enabled: false,
    queryKey: ['GetTransactionAccounts'],
    queryFn: async () => {
      const { data } = await request.get<GetTransactionAccountsResponse>('/transaction-account');
      return data?.data ?? [];
    },
    onSuccess(data) {
      if (data) {
        saveTransactionAccounts(data);
      } else {
        toast.error('Failed to get transaction accounts.');
      }
    },
    onError(error) {
      toast.error(error instanceof Error ? error.message : 'Failed to get transaction accounts.');
    },
  });

  const profileQuery = useQuery({
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
    enabled: configQuery.isSuccess && Boolean(userId) && !isApplyAccountFlyoutOpen,
    queryKey: ['GetUserProfile', userId],
    queryFn: async () => {
      const { data } = await request.get<GetUserProfileResponse>(`/users/${userId}`);
      return data?.data;
    },
    onSuccess(data) {
      if (!data) {
        redirectToSignIn('Failed to get user profile.');
        return;
      }

      const profile = {
        ...data,
        displayName: `${data.firstName} ${data.lastName}`,
      };

      saveProfile(profile);
      saveUserGroup(profile);
      if (profile.backgroundCheck?.status === BackgroundCheckStatus.PASS) {
        transactionAccountsQuery.refetch();
      } else {
        saveTransactionAccounts([]);
      }
    },
    onError(error) {
      redirectToSignIn(error instanceof Error ? error.message : 'Failed to get user profile.');
    },
  });

  useIsomorphicEffect(() => {
    window.addEventListener('storage', handleTokenEvent);
    const newEvent = new StorageEvent('storage');
    window.dispatchEvent(newEvent);

    if (!window.sessionStorage?.getItem('access_token') || '') {
      localStorage.setItem('get_session', nowMel().valueOf().toString());
    }

    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      let token = searchParams.get('access_token');

      if (token) {
        window.sessionStorage.setItem('access_token', token);
        searchParams.delete('access_token');
        searchParams.sort();
        router.replace(
          {
            pathname: window.location.pathname,
            search: searchParams.toString(),
          },
          undefined,
          {
            shallow: true,
          },
        );
      } else {
        token = window.sessionStorage.getItem('access_token');
      }

      if (!token) {
        redirectToSignIn();
        return;
      }

      signIn(token);
      const [, encodedData] = token.split('.');

      if (!encodedData) {
        redirectToSignIn();
        return;
      }

      try {
        const user = JSON.parse(window.atob(encodedData)) as UserDecoded;
        if (!user) {
          redirectToSignIn('Failed to decode user data.');
          return;
        }
        setUserId(user.username);
        mountedRef.current = true;
      } catch {
        redirectToSignIn('Failed to decode user data.');
      }
    }, 100);

    return () => {
      window.removeEventListener('storage', handleTokenEvent);
    };
  }, []);

  useEffect(() => {
    if (!mountedRef.current) return;
    profileQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  return profileQuery;
};
