export enum RequestType {
  REFUND_DEPOSIT = 'REFUND_DEPOSIT',
}

export enum RequestStatus {
  SUBMITTED = 'SUBMITTED',
  RESUBMITTED = 'RESUBMITTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ABANDONED = 'ABANDONED',
}

export const pendingRequestStatus = new Set([
  RequestStatus.SUBMITTED,
  RequestStatus.RESUBMITTED,
  RequestStatus.REJECTED,
  RequestStatus.APPROVED,
]);
