export enum SecuredFundsEntityType {
  CONTRACT = 'CONTRACT',
  PROGRESS_PAYMENT = 'PROGRESS_PAYMENT',
  VARIATION = 'VARIATION',
}

export enum SecurePaidAction {
  ALLOCATED = 'ALLOCATED',
  RELEASED = 'RELEASED',
  PRELOADED = 'PRELOADED',
  REQUESTED = 'REQUESTED',
}

export enum SecurePaidRequestType {
  SECURE = 'SECURE',
  UNSECURE = 'UNSECURE',
}
