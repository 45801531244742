import { type AttachmentCategory } from '@paid-ui/enums/attachment';
import { browserEnvs } from '@paid-ui/env';
import { uriPattern } from '@paid-ui/regexps';
import { type Attachment } from '@paid-ui/schemas/zod/attachment';
import type { Evidence } from '@paid-ui/types';
import { detect } from 'detect-browser';
import sumBy from 'lodash/sumBy';

const { ppaBucketName } = browserEnvs;

const fixFileAddress = (attachment: Attachment | Evidence | string) => {
  const fileAddress = typeof attachment === 'string' ? attachment : attachment.fileAddress;
  return fileAddress.startsWith('/') ? fileAddress.slice(1) : fileAddress;
};

const parseS3Url = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const hostParts = parsedUrl.hostname.split('.');
    const [bucketName = ppaBucketName] = hostParts;
    const key = parsedUrl.pathname.replace(/^\/+/, '');
    return {
      bucketName,
      key: decodeURI(key),
    };
  } catch {
    return {
      bucketName: ppaBucketName,
      key: url,
    };
  }
};

/**
 * Get attachment/evidence pre-signed url
 *
 * @param attachment - Attachment or evidence object
 * @param defaultBucket - Default bucket name
 * @returns Pre-signed Url
 */
export async function getPreSignedUrl(
  attachment: Attachment | Evidence | string,
  defaultBucket?: string,
): Promise<string> {
  const { key, bucketName } =
    typeof attachment === 'string'
      ? parseS3Url(attachment)
      : {
          key: attachment.fileAddress,
          bucketName: attachment.bucketName || defaultBucket || ppaBucketName,
        };

  const headers = new Headers();
  const urlSearchParams = new URLSearchParams();
  const accessToken = sessionStorage.getItem('access_token');

  if (!accessToken) {
    throw new Error('No permit to preview this attachment.');
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `Bearer ${accessToken}`);
  urlSearchParams.set('fileAddress', key);
  urlSearchParams.set('bucketName', bucketName);

  const response = await fetch(`/s3-objects?${urlSearchParams.toString()}`, {
    headers,
  });

  if (!response.ok) {
    throw new Error('Get pre-signed url failed.');
  }

  const json = (await response.json()) as any;
  const preSignedUrl = json?.data?.[key]?.url;

  if (!preSignedUrl) {
    throw new Error('Get pre-signed url failed.');
  }

  return preSignedUrl;
}

/**
 * Get attachment/evidence pre-signed urls
 *
 * @param attachments - Attachment or evidence list
 * @param bucketName - Bucket name
 * @returns Pre-signed Urls
 */
export async function getPreSignedUrls(
  attachments: Attachment[] | Evidence[],
  bucketName: string,
): Promise<Record<string, string>> {
  const [firstAttachment] = attachments;

  if (firstAttachment === undefined) {
    return {};
  }

  // Exception for fake attachment url
  if (uriPattern.test(firstAttachment.fileAddress)) {
    return Object.fromEntries(
      Object.entries(attachments).map(([_, value]) => [value.fileAddress, value.fileAddress]),
    );
  }

  const headers = new Headers();
  const urlSearchParams = new URLSearchParams();
  const accessToken = sessionStorage.getItem('access_token');

  if (!accessToken) {
    throw new Error('No permit to preview this attachment.');
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `Bearer ${accessToken}`);

  urlSearchParams.set('bucketName', attachments.at(0)?.bucketName || bucketName);
  urlSearchParams.set('fileAddress', attachments.map(fixFileAddress).join(','));

  try {
    const response = await fetch(`/s3-objects?${urlSearchParams.toString()}`, {
      headers,
    });

    if (!response.ok) {
      throw new Error('Get pre-signed url failed.');
    }

    const json = (await response.json()) as any;

    const preSignedUrls = json?.data
      ? Object.fromEntries(
          Object.entries(json.data)
            .map(([key, value]: [string, any]) => [fixFileAddress(key), value?.url])
            .filter(Boolean),
        )
      : {};

    if (!preSignedUrls) {
      throw new Error('Get pre-signed urls failed.');
    }

    return preSignedUrls;
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'Failed to get pre-signed urls.');
  }
}

/**
 * Preview document file address directly
 *
 * @param fileAddress - Absolute file address
 */
export async function previewFileAddress(fileAddress: string): Promise<void> {
  const url = await getPreSignedUrl(fileAddress);

  if (!uriPattern.test(url) && !url.startsWith('blob:')) {
    throw new Error('Invalid preview url format');
  }

  const result = detect();
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);

  if (result?.os !== 'iOS' || !result?.name.startsWith('ios')) {
    a.setAttribute('target', '_blank');
  }

  document.body.append(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    a.remove();
  }, 100);
}

/**
 * Preview attachment/evidence by pre-signed url
 *
 * @param attachment - Attachment or evidence object
 * @param bucketName - Bucket name
 */
export async function preview(
  attachment: Attachment | Evidence | Blob | string,
  bucketName?: string,
): Promise<void> {
  let url = '';

  if (typeof attachment === 'string') {
    url = attachment;
  } else if (attachment instanceof Blob) {
    url = URL.createObjectURL(attachment);
  } else if (bucketName) {
    url = await getPreSignedUrl(attachment, bucketName);
  }

  if (!uriPattern.test(url) && !url.startsWith('blob:')) {
    throw new Error('Invalid preview url format');
  }

  const result = detect();
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);

  if (result?.os !== 'iOS' || !result?.name.startsWith('ios')) {
    a.setAttribute('target', '_blank');
  }

  document.body.append(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    a.remove();
  }, 100);
}

/**
 * Preview attachment/evidence by pre-signed url
 *
 * @param attachment - Attachment or evidence object
 * @param bucketName - Bucket name
 */
export async function download(
  attachment: Attachment | Evidence | Blob | string,
  bucketName?: string,
): Promise<void> {
  let url = '';
  let fileName = '';

  if (typeof attachment === 'string') {
    url = attachment;
    fileName = attachment.split('/').at(-1) ?? '';
  } else if (attachment instanceof Blob) {
    url = URL.createObjectURL(attachment);
  } else if (bucketName) {
    url = await getPreSignedUrl(attachment, bucketName);
    fileName = attachment.fileName;
  }

  if (!uriPattern.test(url) && !url.startsWith('blob:')) {
    throw new Error('Invalid download url format');
  }

  const result = detect();
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);

  if (result?.os !== 'iOS' || !result?.name.startsWith('ios')) {
    a.setAttribute('target', '_blank');
  }

  a.setAttribute('download', fileName);
  document.body.append(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    a.remove();
  }, 100);
}

/**
 * Remove object from Amazon S3
 *
 * @param file - File to remove
 * @param bucketName - Bucket name file stored
 */
export async function removeS3Object(file: Attachment | Evidence, bucketName: string) {
  const headers = new Headers();
  const urlSearchParams = new URLSearchParams();
  const accessToken = sessionStorage.getItem('access_token');

  headers.set('Authorization', `Bearer ${accessToken}`);
  headers.set('Content-Type', 'application/json');

  urlSearchParams.set('fileAddress', file.fileAddress);
  urlSearchParams.set('bucketName', file.bucketName || bucketName);

  try {
    await fetch(`/s3-objects?${urlSearchParams.toString()}`, {
      method: 'DELETE',
      headers,
    });
  } catch (error) {
    throw error instanceof Error ? new Error(error.message) : error;
  }
}

/**
 * Format grouped attachment map to flatten attachment list.
 *
 * @param attachmentMap - Attachment map
 * @returns Attachment list
 */
export function formatAttachmentMap(
  attachmentMap: Partial<Record<AttachmentCategory, Attachment[]>>,
): Attachment[] {
  return Object.entries(attachmentMap).flatMap(([category, attachments]) =>
    attachments.map(
      (attachment) =>
        ({
          fileName: attachment.fileName,
          fileAddress: attachment.fileAddress,
          fileSize: attachment.fileSize,
          fileType: attachment.fileType,
          category,
        }) as Attachment,
    ),
  );
}

/**
 * Check if the file size exceed max file size limit.
 *
 * @param file - File array
 * @param maxFileSize - Max size limit
 * @returns Check result
 */
export function validateFileSize(file: File | File[], maxFileSize = -1): boolean {
  const files = Array.isArray(file) ? file : [file];

  if (maxFileSize <= 0) {
    return true;
  }

  const totalFileSize = sumBy(files, (_file) => _file.size);

  return totalFileSize <= maxFileSize;
}

/**
 * Check if file is a PDF document or not with file name.
 *
 * @param file - Attachment or evidence
 * @returns File is PDF document or not
 */
export function isDocument(file: Attachment | Evidence): boolean {
  const fileName = file.fileName.toLowerCase();
  return fileName.endsWith('.pdf');
}

/**
 * Check if file is a photo or not with file name.
 *
 * @param file - Attachment or evidence
 * @returns File is photo or not
 */
export function isPhoto(file: Attachment | Evidence) {
  const fileName = file.fileName.toLowerCase();
  return ['.jpg', '.jpeg', '.png', '.webp', '.avif'].some((ext) => fileName.endsWith(ext));
}

/**
 * Check if file is a video or not with file name.
 *
 * @param file - Attachment or evidence
 * @returns File is photo or not
 */
export function isVideo(file: Attachment | Evidence) {
  const fileName = file.fileName.toLowerCase();
  return fileName.endsWith('.mp4') || fileName.endsWith('.mov');
}
