import { RequestStatus } from '@paid-ui/enums/request';
import { SecurePaidAction } from '@paid-ui/enums/secure-paid';

export const securePaidRequestStatusMap = {
  [RequestStatus.SUBMITTED]: 'Pending',
  [RequestStatus.RESUBMITTED]: 'Pending',
  [RequestStatus.APPROVED]: 'Approved',
  [RequestStatus.REJECTED]: 'Rejected',
  [RequestStatus.ABANDONED]: 'Abandoned',
};

export const securePaidActionMap = {
  [SecurePaidAction.PRELOADED]: 'Preloaded',
  [SecurePaidAction.RELEASED]: 'Released',
  [SecurePaidAction.ALLOCATED]: 'Transferred',
  [SecurePaidAction.REQUESTED]: 'Preloaded funds request',
};

export const unsecurePaidActionMap = {
  [SecurePaidAction.PRELOADED]: 'Preloaded',
  [SecurePaidAction.RELEASED]: 'Released',
  [SecurePaidAction.ALLOCATED]: 'Transferred',
  [SecurePaidAction.REQUESTED]: 'Unsecure funds request',
};
