import { ContractRole } from '@paid-ui/enums/contract';
import { AgentIcon } from '@paid-ui/icons/agent';
import { BrushIcon } from '@paid-ui/icons/brush';
import { DeliveryTruckIcon } from '@paid-ui/icons/delivery-truck';
import { HelmetIcon } from '@paid-ui/icons/helmet';
import { OwnerKeyIcon } from '@paid-ui/icons/owner-key';
import { ArchitectIcon } from '@paid-ui/icons/sketch';
import { UserIcon } from '@paid-ui/icons/user';
import { FileQuestionIcon } from 'lucide-react';

export const contractRoleIconMap = {
  [ContractRole.PRINCIPAL]: OwnerKeyIcon,
  [ContractRole.CONTRACTOR]: HelmetIcon,
  [ContractRole.BUILDER]: HelmetIcon,
  [ContractRole.SUBCONTRACTOR]: BrushIcon,
  [ContractRole.SUPPLIER]: DeliveryTruckIcon,
  [ContractRole.ARCHITECT]: ArchitectIcon,
  [ContractRole.SUPERINTENDENT]: AgentIcon,
  [ContractRole.QUANTITY_SURVEYOR]: FileQuestionIcon,
  [ContractRole.OFFEREE]: UserIcon,
  [ContractRole.OFFEROR]: OwnerKeyIcon,
} as Record<ContractRole, React.FC<React.SVGProps<SVGSVGElement>>>;
