import { ClaimMethod } from '@paid-ui/enums/claim';

/**
 * See details
 * https://paid-inc.atlassian.net/wiki/spaces/AD/pages/16613477/Claims
 */
export enum ClaimStatus {
  'SUBMITTED' = 'SUBMITTED',
  'REJECTED' = 'REJECTED',
  'RESUBMITTED' = 'RESUBMITTED',
  'PRE_APPROVED' = 'PRE_APPROVED',
  'APPROVED' = 'APPROVED',
  'PROVISIONAL' = 'PROVISIONAL',
  'PAID' = 'PAID',
}

export enum ClaimSource {
  ON_PLATFORM = 'ON_PLATFORM',
  OFF_PLATFORM = 'OFF_PLATFORM',
}

export enum ClaimLinkStatus {
  MATCH = 'MATCH',
  MANUAL_MATCH = 'MANUAL_MATCH',
  NO_MATCH = 'NO_MATCH',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum ClaimPaymentStatus {
  SUBMITTED = 'SUBMITTED',
  RESUBMITTED = 'RESUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PAID = 'PAID',
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
}

export enum ReviewClaimAction {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ClaimPaymentStep {
  PROJECT_CONTRACT_PAYMENTS,
  VARIATIONS_AND_ADJUSTMENTS,
  INSURANCE_CERTIFICATE,
  EVIDENCES,
  DOCUMENTS,
  REVIEW,
}

export enum ReviewClaimStep {
  SUMMARY,
  VARIATIONS_AND_ADJUSTMENTS,
  INSURANCE_CERTIFICATE,
  EVIDENCES,
  DOCUMENTS,
  REFINANCING,
  HOME_INSURED,
  HOME_INSURANCE,
  CONNECTED_SERVICE,
  APPROVE_REJECT,
}

export interface ClaimPaymentFormStepper {
  index: ClaimPaymentStep;
  title: string;
  description?: string;
  included: boolean;
}

export interface ReviewClaimFormStepper {
  index: ReviewClaimStep;
  title: string;
  description?: string;
  included: boolean;
}

export const claimPaymentSteps: ClaimPaymentFormStepper[] = [
  {
    index: ClaimPaymentStep.PROJECT_CONTRACT_PAYMENTS,
    title: 'Submit claim',
    description: 'Select progress payment',
    included: true,
  },
  {
    index: ClaimPaymentStep.VARIATIONS_AND_ADJUSTMENTS,
    title: 'Submit claim',
    description: 'Variations and adjustments',
    included: true,
  },
  {
    index: ClaimPaymentStep.INSURANCE_CERTIFICATE,
    title: 'Submit claim',
    description: 'Domestic building insurance',
    included: false,
  },
  {
    index: ClaimPaymentStep.EVIDENCES,
    title: 'Submit claim',
    description: 'Evidence: videos and photos',
    included: true,
  },
  {
    index: ClaimPaymentStep.DOCUMENTS,
    title: 'Submit claim',
    description: 'Evidence: documents',
    included: true,
  },
  {
    index: ClaimPaymentStep.REVIEW,
    title: 'Submit claim',
    description: 'Summary',
    included: true,
  },
];

export const reviewClaimStepsWithRefinancing: ReviewClaimFormStepper[] = [
  {
    index: ReviewClaimStep.SUMMARY,
    title: 'Review payment claim',
    description: 'Summary of requested claim',
    included: true,
  },
  {
    index: ReviewClaimStep.VARIATIONS_AND_ADJUSTMENTS,
    title: 'Review payment claim',
    description: 'Variations and adjustments',
    included: true,
  },
  {
    index: ReviewClaimStep.INSURANCE_CERTIFICATE,
    title: 'Review payment claim',
    description: 'Domestic building insurance',
    included: false,
  },
  {
    index: ReviewClaimStep.EVIDENCES,
    title: 'Review payment claim',
    description: 'Evidence: videos and photos',
    included: true,
  },
  {
    index: ReviewClaimStep.DOCUMENTS,
    title: 'Review payment claim',
    description: 'Evidence: documents',
    included: true,
  },
  {
    index: ReviewClaimStep.REFINANCING,
    title: 'Review payment claim',
    description: 'Refinancing',
    included: false,
  },
  {
    index: ReviewClaimStep.HOME_INSURED,
    title: 'Review payment claim',
    description: 'Home insurance',
    included: false,
  },
  {
    index: ReviewClaimStep.HOME_INSURANCE,
    title: 'Review payment claim',
    description: 'Home insurance',
    included: false,
  },

  {
    index: ReviewClaimStep.CONNECTED_SERVICE,
    title: 'Review payment claim',
    description: 'Connected services',
    included: false,
  },
  {
    index: ReviewClaimStep.APPROVE_REJECT,
    title: 'Review payment claim',
    description: 'Approve or reject',
    included: true,
  },
];

export const claimPaymentStatusMap = {
  [ClaimPaymentStatus.SUBMITTED]: 'Submitted',
  [ClaimPaymentStatus.RESUBMITTED]: 'Resubmitted',
  [ClaimPaymentStatus.APPROVED]: 'Approved',
  [ClaimPaymentStatus.REJECTED]: 'Rejected',
  [ClaimPaymentStatus.PAID]: 'Paid',
  [ClaimPaymentStatus.PARTIAL_PAYMENT]: 'Partial Payment',
};

export enum ConnectedServices {
  GAS = 'gas',
  WATER = 'water',
  ELECTRICITY = 'electricity',
  INTERNET = 'internet',
}

export enum LeadTypes {
  SECOND_LAST_CLAIM = 'SECOND_LAST_CLAIM',
  COMPLETE_CONTRACT = 'COMPLETE_CONTRACT',
}

export enum UiClaimedStatus {
  UNCLAIMED = 'UNCLAIMED',
  CLAIMED_UNPAID = 'CLAIMED_UNPAID',
  PAID = 'PAID',
}

export const claimMethodMap = {
  [ClaimMethod.STAGED]: 'Staged',
  [ClaimMethod.PERIODIC]: 'Periodic',
};
