import { ContractType, postExecutedContractState, VariationStage } from '@paid-ui/constants';
import { type ContractState } from '@paid-ui/enums/contract';
import { type Contract } from '@paid-ui/models/contract';
import { type Address } from '@paid-ui/schemas/zod/address';
import { sum } from 'lodash';

import { utc2Mel } from './datetime';
import { formatAddress } from './formatter';

/**
 * Get total construction days of contract.
 *
 * @param contract Current contract
 */
export function getConstructionDays(contract?: Contract) {
  if (!contract) return 0;

  const {
    contractType,
    variations = [],
    constructionDays = 0,
    initialConstructionDays = 0,
  } = contract;

  if (constructionDays) {
    return constructionDays;
  }

  const variationDays = variations.reduce(
    (result, { variationStage, reasonableDelayDays = 0 }) =>
      result +
      (variationStage &&
      [VariationStage.APPROVED, VariationStage.COMPLETED].includes(variationStage)
        ? reasonableDelayDays
        : 0),
    0,
  );

  switch (contractType) {
    case ContractType.HEAD_CONTRACT: {
      const {
        inclementWeather = 0,
        weekendDays = 0,
        weekdayPublicHolidays = 0,
        rosteredDaysOff = 0,
        foreseeableBreaks = 0,
        builderHolidays = 0,
        additionalDaysFromVariations = 0,
        reasonableDelays,
      } = contract;

      return sum([
        inclementWeather,
        weekendDays,
        weekdayPublicHolidays,
        rosteredDaysOff,
        foreseeableBreaks,
        builderHolidays,
        additionalDaysFromVariations,
        reasonableDelays?.days ?? 0,
        variationDays,
        initialConstructionDays,
      ]);
    }

    case ContractType.SUBCONTRACT:
    case ContractType.SUPPLY_CONTRACT: {
      return sum([variationDays, initialConstructionDays]);
    }

    default: {
      return 0;
    }
  }
}

/**
 * Get completion date
 *
 * @param contract - Contract
 * @returns completed at
 */
export function getCompletionDate(contract?: Contract) {
  if (!contract?.commencementDate) return null;
  const { actualCompletionDate, anticipatedCompletionDate, commencementDate } = contract;

  if (actualCompletionDate) {
    return utc2Mel(actualCompletionDate);
  } else if (anticipatedCompletionDate) {
    return utc2Mel(anticipatedCompletionDate);
  }

  const constructionDays = getConstructionDays(contract);
  return utc2Mel(commencementDate).add(constructionDays, 'day');
}

/**
 * Get completion date
 *
 * @param contract - Contract
 * @param dateFormat - date format
 * @returns completed at
 */
export function getCompletionDateInFormat(contract?: Contract, dateFormat = 'D MMM YYYY') {
  const date = getCompletionDate(contract);
  return date ? date.format(dateFormat) : '-';
}

export const getFullContractName = (contractName: string, address: Address | null = null) => {
  return address
    ? [contractName, formatAddress(address, true)].filter(Boolean).join(' · ')
    : contractName;
};

type ContractLikeObject = {
  hasAllSigned?: boolean;
  contractState?: ContractState | '';
  [key: string]: any;
};

export const checkIfContractIsExecuted = (contract: ContractLikeObject) => {
  return (
    !!contract.hasAllSigned &&
    !!contract.contractState &&
    postExecutedContractState.has(contract.contractState)
  );
};

export const templateCodeToUrl = (templateCode?: string) => {
  const prefix = 'OFF_PLATFORM';

  if (!templateCode) {
    return '';
  }

  if (templateCode.startsWith(prefix)) {
    return [prefix, ...templateCode.replace(`${prefix}_`, '').split('_')].join('/');
  }

  return templateCode.split('_').join('/');
};
