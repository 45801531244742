export const AgentIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.8443 15.872L15.0403 6L15.9013 2.98C16.1283 2.291 16.0103 1.527 15.5843 0.939C15.1583 0.351 14.4713 0 13.7453 0H10.2573C9.53133 0 8.84333 0.351 8.41833 0.939C7.99233 1.527 7.87433 2.29 8.08933 2.941L8.96133 5.999L6.17033 15.828C5.66933 17.33 6.10233 18.988 7.27433 20.053L11.3283 23.739C11.5193 23.912 11.7603 23.999 12.0013 23.999C12.2423 23.999 12.4833 23.912 12.6743 23.739L16.7283 20.054C17.9003 18.989 18.3323 17.331 17.8443 15.872ZM10.0373 2.111C10.0743 2.06 10.1413 1.999 10.2563 1.999H13.7443C13.8583 1.999 13.9263 2.06 13.9633 2.111C14.0003 2.162 14.0373 2.245 13.9893 2.392L13.2453 4.999H10.7543L9.99933 2.354C9.96333 2.245 9.99933 2.162 10.0373 2.111ZM15.3813 18.574L12.0003 21.648L8.61833 18.574C8.03333 18.042 7.81633 17.213 8.08033 16.419L10.7553 7H13.2443L15.9333 16.462C16.1833 17.213 15.9673 18.042 15.3813 18.574Z" />
  </svg>
);
