export enum FeatureItem {
  KYC_KYB = 'KYC_KYB',
  USER_CONSENT = 'USER_CONSENT',
  USER_MOBILE_VERIFICATION = 'USER_MOBILE_VERIFICATION',
  WATCHMAN = 'WATCHMAN',
  ON_PLATFORM_RETENTION = 'ON_PLATFORM_RETENTION',
  CARD_PAYMENT_RBN = 'CARD_PAYMENT_RBN',
  CARD_PAYMENT_PAID_SECONDARY_CONTRACTS = 'CARD_PAYMENT_PAID_SECONDARY_CONTRACTS',
  CARD_PAYMENT_SUPER_CONTRACTS = 'CARD_PAYMENT_SUPER_CONTRACTS',
  CONTRACT_FORM_NAVIGATOR = 'CONTRACT_FORM_NAVIGATOR',
  SECURE_CONTRACT = 'SECURE_CONTRACT',
}

export enum FeatureGroup {
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  CLAIM_PAYMENT = 'CLAIM_PAYMENT',
  BANKING_SYSTEM = 'BANKING_SYSTEM',
  INSTAPAID = 'INSTAPAID',
  MISCELLANEOUS = 'MISCELLANEOUS',
  RESOURCE_LOCKING = 'RESOURCE_LOCKING',
}

export enum FeatureDeployStatus {
  DEPLOYED = 'DEPLOYED',
}

export enum FeatureToggleStatus {
  ON = 'ON',
  OFF = 'OFF',
}
