import { RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';

export const requiringActionLabelMap = {
  JOIN_CONTRACT: 'Join contract',
  UPDATE_CONTRACT: 'Resubmit contract',
  REVIEW_CONTRACT: 'Review contract',
  SIGN_CONTRACT: 'Sign contract',
  SUBMIT_CLAIM_CONTRACT: 'Create claim',
  SUBMIT_CLAIM: 'Submit claim',
  SUBMIT_BACKGROUND_CHECK: 'Background check',
  PAY_CONTRACT_DEPOSIT: 'Pay contract deposit',
  REVIEW_REFUND_REQUEST: 'Review refund request',
  UPDATE_REFUND_REQUEST: 'Resubmit refund request',

  REVIEW_TERMINATE_CONTRACT_REQUEST: 'Review termination request',
  UPDATE_TERMINATE_CONTRACT_REQUEST: 'Resubmit termination request',

  REVIEW_CLAIM: 'Review claim',
  UPDATE_CLAIM: 'Resubmit claim',
  PAY_CLAIM: 'Pay claim',

  REVIEW_VARIATION: 'Review variation',
  UPDATE_VARIATION: 'Resubmit variation',

  REVIEW_ADJUSTMENT: 'Review adjustment',
  UPDATE_ADJUSTMENT: 'Resubmit adjustment',

  REVIEW_SECURE_PAID_REQUEST: 'Review preloaded funds',
  UPDATE_SECURE_PAID_REQUEST: 'Preload funds',
  REVIEW_UNSECURE_PAID_REQUEST: 'Review unsecured funds',
  UPDATE_UNSECURE_PAID_REQUEST: 'Unsecure funds',
  SECURE_CONTRACT: 'Secure contract',
  REVIEW_EARLY_RELEASE_REQUEST: 'Review InstaPaid counter offer.',
};

export const requiringActionSearchParamMap = {
  JOIN_CONTRACT: 'complete-contract',
  UPDATE_CONTRACT: 'amend-contract',
  REVIEW_CONTRACT: 'review-contract',
  SIGN_CONTRACT: 'sign-contract',
  PAY_CONTRACT_DEPOSIT: 'account-details',
  SUBMIT_CLAIM_CONTRACT: 'new-claim',
  SUBMIT_CLAIM: 'new-claim',
  SUBMIT_BACKGROUND_CHECK: 'apply-account',

  REVIEW_REFUND_REQUEST: 'deposit-refund',
  UPDATE_REFUND_REQUEST: 'deposit-refund',

  REVIEW_TERMINATE_CONTRACT_REQUEST: 'terminate-contract',
  UPDATE_TERMINATE_CONTRACT_REQUEST: 'terminate-contract',

  REVIEW_CLAIM: 'review-claim',
  UPDATE_CLAIM: 'amend-claim',
  PAY_CLAIM: 'pay-claim',

  NEW_VARIATION: 'new-variation',
  REVIEW_VARIATION: 'review-variation',
  UPDATE_VARIATION: 'amend-variation',

  NEW_ADJUSTMENT: 'new-adjustment',
  REVIEW_ADJUSTMENT: 'review-adjustment',
  UPDATE_ADJUSTMENT: 'amend-adjustment',

  REVIEW_SECURE_PAID_REQUEST: 'secure-paid',
  UPDATE_SECURE_PAID_REQUEST: 'secure-paid',
  REVIEW_UNSECURE_PAID_REQUEST: 'unsecure-paid',
  UPDATE_UNSECURE_PAID_REQUEST: 'unsecure-paid',
  SECURE_CONTRACT: 'secure-paid',
  REVIEW_EARLY_RELEASE_REQUEST: 'earlyrelease-review-offer',
};

export const contractRequiringActionSet = new Set([
  RequiringActionFeatureType.CONTRACT,
  RequiringActionFeatureType.CONTRACT_DEPOSIT,
  RequiringActionFeatureType.REFUND_REQUEST,
  RequiringActionFeatureType.SECURE_PAID_REQUEST,
  RequiringActionFeatureType.UNSECURE_PAID_REQUEST,
  RequiringActionFeatureType.TERMINATE_CONTRACT_REQUEST,
]);
