export enum RequiringActionType {
  SUBMIT_CLAIM = 'SUBMIT_CLAIM',
  UPDATE = 'UPDATE',
  REVIEW = 'REVIEW',
  PAY = 'PAY',
  SIGN = 'SIGN',
  JOIN = 'JOIN',
  SECURE = 'SECURE',
}

export enum RequiringActionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum RequiringActionFeatureType {
  CONTRACT = 'CONTRACT',
  CONTRACT_DEPOSIT = 'CONTRACT_DEPOSIT',
  VARIATION = 'VARIATION',
  ADJUSTMENT = 'ADJUSTMENT',
  CLAIM = 'CLAIM',
  EARLY_RELEASE_REQUEST = 'EARLY_RELEASE_REQUEST',
  SECURE_PAID_REQUEST = 'SECURE_PAID_REQUEST',
  UNSECURE_PAID_REQUEST = 'UNSECURE_PAID_REQUEST',
  REFUND_REQUEST = 'REFUND_REQUEST',
  TERMINATE_CONTRACT_REQUEST = 'TERMINATE_CONTRACT_REQUEST',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
}

export enum ContractAction {
  CONTRACT_REVIEW = 'CONTRACT_REVIEW',
  CONTRACT_AMEND = 'CONTRACT_AMEND',
  CONTRACT_SIGN = 'CONTRACT_SIGN',
  CLAIM_SUBMIT = 'CLAIM_SUBMIT',
  CLAIM_REVIEW = 'CLAIM_REVIEW',
  CLAIM_EDIT = 'CLAIM_EDIT',
  CLAIM_ASSESSED = 'CLAIM_ASSESSED',
  VARIATION_SUBMIT = 'VARIATION_SUBMIT',
  VARIATION_REVIEW = 'VARIATION_REVIEW',
  CLAIM_PAY = 'CLAIM_PAY',
}
