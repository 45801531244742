export enum ProjectType {
  DOMESTIC_RESIDENTIAL = 'DOMESTIC_RESIDENTIAL',
  MULTI_RESIDENTIAL = 'MULTI_RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  INDUSTRIAL = 'INDUSTRIAL',
  CIVIL = 'CIVIL',
  REAL_ESTATE = 'REAL_ESTATE',
}

export enum ProjectRole {
  PRINCIPAL = 'PRINCIPAL',
  BUILDER = 'BUILDER',
  SUPERINTENDENT = 'SUPERINTENDENT',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER',
  ARCHITECT = 'ARCHITECT',
}

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ProjectIndustry {
  CONSTRUCTION = 'CONSTRUCTION',
  REAL_ESTATE = 'REAL_ESTATE',
}
